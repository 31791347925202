import React from 'react';
import DrawerAddUser from './body/add';
import DrawerProfile from './body/profile';
import { JSONSchema, TError, TStandardSectionStep, TUserStatus } from 'models';
import { AxiosError } from 'axios';
import { TUser } from '../../common';
import { TTableData as TBrokerTableData } from '../../Broker';
import { DrawerStyledAdmin } from './styles';

interface IProps {
  type: string,
  scheme?: JSONSchema,
  dataStatus: string,
  errors: TError[],
  step: TStandardSectionStep,
  brokerList: TBrokerTableData,
  selectedUser: TUser | null,
  userId?: string,
  isMasking: boolean,
  handleDrawerClose: () => void,
  updateStep: (step: string) => void,
  handlePatchUser: (selectedUser: TUser, onError?: (errors: AxiosError) => void) => void,
  handlePatchUserStatus: (status: TUserStatus, onError?: (errors: AxiosError) => void) => void,
  handleMaskUser: (userId: string, onError?: (errors: AxiosError) => void) => void,
  handleDeleteUser: () => void,
  updateSelectedUser: (user: TUser) => void,
  handlePostUser: (user: TUser, onError: (error: AxiosError) => void) => void,
}

const Drawer = ({
  type,
  selectedUser,
  userId,
  handleDrawerClose,
  updateStep,
  step,
  dataStatus,
  errors,
  brokerList,
  isMasking,
  handlePatchUser,
  handlePatchUserStatus,
  handleMaskUser,
  handleDeleteUser,
  updateSelectedUser,
  handlePostUser,
}: IProps) => (
  <DrawerStyledAdmin show={!!selectedUser || !!userId || type === 'add'} dir="right" step={step} $userRole="admin">
    {DrawerBody({
      type,
      selectedUser,
      userId,
      handleDrawerClose,
      updateStep,
      step,
      dataStatus,
      errors,
      brokerList,
      isMasking,
      handlePatchUser,
      handlePatchUserStatus,
      handleMaskUser,
      handleDeleteUser,
      updateSelectedUser,
      handlePostUser
    })}
  </DrawerStyledAdmin>
);

const DrawerBody = ({
  type,
  selectedUser,
  userId,
  handleDrawerClose,
  updateStep,
  step,
  dataStatus,
  errors,
  brokerList,
  isMasking,
  handlePatchUser,
  handlePatchUserStatus,
  handleMaskUser,
  handleDeleteUser,
  updateSelectedUser,
  handlePostUser
}: IProps) => {

  switch (type) {
    case 'add':
      return (
        <DrawerAddUser
          handleDrawerClose={handleDrawerClose}
          handlePostUser={handlePostUser}
          brokerList={brokerList}
        />
      );
    case 'profile':
    default:
      return (
        <DrawerProfile
          selectedUser={selectedUser}
          userId={userId}
          handleDrawerClose={handleDrawerClose}
          updateStep={updateStep}
          step={step}
          dataStatus={dataStatus}
          errors={errors}
          brokerList={brokerList}
          isMasking={isMasking}
          handlePatchUser={handlePatchUser}
          handlePatchUserStatus={handlePatchUserStatus}
          handleMaskUser={handleMaskUser}
          handleDeleteUser={handleDeleteUser}
          updateSelectedUser={updateSelectedUser}
        />
      );
  }
};

export default Drawer;
