import styled from 'styled-components';

export const LoadingScreen = styled.div<{ $isAuthenticated: boolean }>`
  background-color: ${({ theme, $isAuthenticated }) => $isAuthenticated ? theme.colors.white: null};
  display: flex;
  height: 100vh;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto;
`;

export const AppWrapper = styled.div<{ $isAuthenticated: boolean }>`
  background-color: ${({ theme, $isAuthenticated }) => $isAuthenticated ? theme.colors.white: null};
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;
