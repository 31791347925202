import { DrawerBodyStyled, DrawerStyled, DrawerUserTypeLabel } from 'app/Components/common/Drawer/styles';
import styled from 'styled-components';
import { TStandardSectionStep } from 'models';
import { Select, Dropdown, Button } from '@insly/qmt-reactjs-ui-lib';
import { DefaultIcon } from 'styles/common';
import { DropCommonPicker, DropCommonPickerContent } from 'app/Components/common/DropdownSingle/styles';

export const DrawerStyledAdmin = styled(DrawerStyled)<{ $userRole: string, step?: TStandardSectionStep }>`
  width: ${({ step, $userRole }) => ($userRole === 'admin' && step === 'profile') ? '472px' : null};
`;

export const BodySection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeB};
  margin-right: 10px;
`;

export const SelectAdmin = styled(Select)`
  border: none;
`;

export const DrawerBodyAdmin = styled(DrawerBodyStyled)`
  padding-top: 0;
`;

export const UserLanguageDropdown = styled(Dropdown)`
  display: block;
`;

export const DropUserPickerContent = styled(DropCommonPickerContent)`
  height: 32px;
  margin-right: auto;
  align-items: flex-start;
`;

export const DropUserLanguageIcon = styled(DefaultIcon)`
  margin-right: 16px;
`;

export const DropUserLanguagePicker = styled(DropCommonPicker)`
  &:hover {
    ${DrawerUserTypeLabel} {
      color: ${({ theme }) => theme.colors.text};
    }
  }
`;

export const ButtonMask = styled(Button)<{ isMasking: boolean }>`
  margin-top: 12px;
  width: 100%;
  background-color: ${({ theme, isMasking }) => !isMasking && theme.colors.primary};
`;

export const UserRoleStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 14px;
`;

export const UserRoleIcon = styled(DefaultIcon)`
  stroke: ${({ theme }) => theme.colors.black};
  fill: ${({ theme }) => theme.colors.black};
  margin-right: 16px;
`;

export const UserRoleLabel = styled.div`
  ${({ theme }) => theme.text.label};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 8px;
  text-transform: uppercase;
`;

export const UserRoleTitle = styled.h3`
 ${({ theme }) => theme.text.normal};
`;
