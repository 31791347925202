import React, { useEffect, useRef, useState } from 'react';
import {
  createTempId,
  makeRequestQuery,
  preflightAPICall,
  processAPIError,
  serverDateTimeFormatter,
  unicodeReplacer
} from 'utils';
import apiConfig from 'config/api';
import { TDoc, TDocsListData } from 'models';
import axios from 'axios';
import Pagination from 'app/Components/common/Pagination';
import {
  DocumentsStyled,
  UploaderDocuments,
  UploaderDocumentsIcon,
  UploaderDocumentsString,
  UploaderInput
} from './styles';
import DocumentsList from 'app/Components/DocumentsList';
import { TDomain, useAuth } from 'context/Auth';

const DRAG_STYLE_TIMEOUT_DELAY = 800;

let removeDragStyleTimeout: ReturnType<typeof setTimeout>;

interface IPageDocuments {
  refId: string,
  docType: string,
  isBroker?: boolean,
  userDomain?: TDomain,
}

const Documents = ({ refId, docType, isBroker, userDomain } : IPageDocuments) => {
  const [dataStatus, updateDataStatus] = useState('loading');
  const [documentsList, updateList] = useState<TDocsListData>({});
  const [noResultsText, updateNoResultsText] = useState('No Results');

  useEffect(() => {
    callList({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docType]);

  const callList = ({ page }: {page?: number}) => {
    updateDataStatus('loading');
    let query: Record<string, string | number> = {};

    if (page) {
      query.page = page;
    }

    if (isBroker) {
      query.scope = 'common';
    }

    query['document_type_ids[]'] = docType;

    const queryString = makeRequestQuery(query);

    preflightAPICall(() => {
      axios.get(`${apiConfig.DOCUMENT}/${refId}${queryString}`).then(response => {
        if (response.status === 200) {
          const data = response.data;

          if (!data.results) {
            data.results = [];
          }
          dataLoading(data);
        }

      }).catch(error => {

        dataLoading({
          results: [],
        });

        updateNoResultsText(() => processAPIError(error) as string);

      });
    });
  };

  const dataLoading = (data: TDocsListData) => {
    updateList(data);
    updateDataStatus('success');
  };

  const handleListChangePage = (page: number) => {
    callList({ page });
  };

  const handleRemoveDoc = (id: string) => {
    const results = documentsList.results || [];
    const docIndex = results.findIndex(i => i.id === id);
    if (docIndex !== -1) {
      results?.splice(docIndex, 1);
      updateList({
        ...documentsList,
        results
      });
    }
  };

  const handleAddDoc = (doc: TDoc) => {
    const data = documentsList.results || [];
    data.unshift(doc);
    updateList({
      ...documentsList,
      results: data
    });
  };

  const handleUpdateTempDoc = (doc: TDoc) => {
    const results = documentsList.results || [];
    const docIndex = results.findIndex(i => i.temp_id && i.temp_id === doc.temp_id);
    if (docIndex !== -1) {
      results[docIndex] = doc;
      updateList({
        ...documentsList,
        results
      });
    }
  };

  return (
    <DocumentsStyled>
      <Uploader
        handleAddDoc={handleAddDoc}
        handleUpdateTempDoc={handleUpdateTempDoc}
        customerId={refId}
        docType={docType}
        isBroker={isBroker}
        userDomain={userDomain}
      />
      <DocumentsList
        refId={refId as string}
        dataStatus={dataStatus}
        data={documentsList.results as never[]}
        noResultsText={noResultsText}
        handleRemoveDoc={handleRemoveDoc}
      />
      {documentsList?.last_page && documentsList.last_page > 1 ? Pagination({ page: documentsList.page as number, last_page: documentsList.last_page }, handleListChangePage) : null}
    </DocumentsStyled>
  );

};

const Uploader = ({
  handleAddDoc,
  handleUpdateTempDoc,
  customerId,
  docType,
  isBroker,
  userDomain
}: {
  handleAddDoc: (item: TDoc) => void,
  handleUpdateTempDoc: (doc: TDoc) => void,
  customerId: string,
  docType: string,
  isBroker?: boolean,
  userDomain?: TDomain,
}) => {
  const { user } = useAuth();

  const uploadInput = useRef<HTMLInputElement>(null);

  const getXDomain = () => {
    if (isBroker) {
      if (user?.masquerade && user.masquerade.all_roles?.length) {
        const obj = {
          'props': {
            'tenant': `${user.masquerade.all_roles[0].domain.props.tenant}`,
            'agency': `${user.masquerade.all_roles[0].domain.props.agency}`
          },
          'meta': {
            'tenant': `${user.masquerade.all_roles[0].domain.meta.tenant}`,
            'agency': `${user.masquerade.all_roles[0].domain.meta.agency}`
          }
        };

        return JSON.stringify(obj, unicodeReplacer);
      }

      return '';
    }

    return JSON.stringify(userDomain, unicodeReplacer);
  };

  const onDragOver = (event: React.MouseEvent) => {
    event.preventDefault();
    if (uploadInput.current !== null && uploadInput.current.parentNode) {
      const element = uploadInput.current.parentNode as HTMLElement;
      element.classList.add('drag-over');
    }
  };

  const onDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    clearTimeout(removeDragStyleTimeout);
    removeDragStyleTimeout = setTimeout(() => {
      if (uploadInput.current !== null && uploadInput.current.parentNode) {
        const element = uploadInput.current.parentNode as HTMLElement;
        element.classList.remove('drag-over');
      }
    }, DRAG_STYLE_TIMEOUT_DELAY);
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    onUploadDrop(event);
    if (uploadInput.current !== null && uploadInput.current.parentNode) {
      const element = uploadInput.current.parentNode as HTMLElement;
      element.classList.remove('drag-over');
    }
  };

  const onUploadDrop = (event: React.DragEvent) => {
    if (event.dataTransfer && event.dataTransfer.files.length !== 0) {
      onUpload(event.dataTransfer.files, customerId);
    }
  };

  const onUpload = (files: FileList, customerId: string) => {
    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);

    const name = file.name;
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);

    let document: TDoc = {
      'scope': isBroker ? 'common' : 'domain',
      'type_id': docType,
      'file_name': name,
      'title': fileName,
      'attached_at': serverDateTimeFormatter(new Date()),
      'created_user_name': user ? user.name : '',
      'props': {
        ext: ext,
        size: `${file.size}`,
      },
    };

    formData.append('data', JSON.stringify(document));

    let tempDocument: TDoc = {
      ...document,
      temp_id: createTempId(),
      progress: 0,
    };

    handleAddDoc(tempDocument);

    preflightAPICall(() => {
      axios.post(`${apiConfig.MASTER_DOCUMENT}/${customerId}`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'X-Domain': getXDomain()
          },
          onUploadProgress: progressEvent => {
            handleUpdateTempDoc({
              ...tempDocument,
              progress: progressEvent.total && progressEvent.loaded / progressEvent.total * 100
            });
          },
        }).then(response => {
        if (response.status === 207) {
          handleUpdateTempDoc({
            ...tempDocument,
            progress: null,
            errors: response.data?.errors
          });

          return;
        }

        document = {
          ...document,
          ...response.data,
          'download_path': `${apiConfig.DOCUMENT}/${customerId}/${response.data.id}/file`,
          'download_method': 'GET',
        };

        handleUpdateTempDoc({
          ...tempDocument,
          ...document,
          progress: null
        });
      }).catch(error => {
        if (error.response) {
          handleUpdateTempDoc({
            ...tempDocument,
            progress: null,
            errors: error.response.data ? (error.response.data?.errors ? error.response.data?.errors : [error.response.data]) : [{ code: '', message: 'error.common' }]
          });
        }
      });
    });
  };

  return (
    <UploaderDocuments
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={() => {
        if (uploadInput.current !== null) {
          uploadInput.current.click();
        }
      }}
    >
      <UploaderInput
        type="file"
        ref={uploadInput}
        name="file"
        multiple={false}
        onChange={() => {
          if (uploadInput.current !== null && uploadInput.current.files) {
            onUpload(uploadInput.current.files, customerId);
          }
        }}
      />
      <UploaderDocumentsString>
        {/*{translate({ key: 'customer.documents.drop_file' })} <UploaderDocumentsIcon icon="upload" margin="left" />*/}
        Upuść pliki tutaj <UploaderDocumentsIcon icon="upload" margin="left" />
      </UploaderDocumentsString>
      <UploaderDocumentsString>
        {/*<UploaderDocumentsIcon icon="folder" margin="right" /> {translate({ key: 'customer.documents.browse_file' })}*/}
        <UploaderDocumentsIcon icon="folder" margin="right" /> Przeglądaj i wybierz plik
      </UploaderDocumentsString>
    </UploaderDocuments>
  );
};

export default Documents;
