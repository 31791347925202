import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { CSSReset } from './styles/reset';
import { ReactComponent as SVGSprite } from './assets/icons/icons.svg';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SVGSprite style={{ position: 'absolute', height: '0px', width: '0px' }} />
      <CSSReset />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
