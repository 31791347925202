import { AxiosError } from 'axios';
import { TError } from 'models';
import { translate } from './translate';

type errorType = 'masterService';

export const processAPIError = (error: AxiosError, array?: boolean, errorType?: errorType) => {
  let errorData: string | TError[];
  if (array) {
    errorData = [{ code: '', message: translate({ key: 'common.error' }) }];
  } else {
    errorData = translate({ key: 'common.error' });
  }

  switch (errorType) {
    case 'masterService':
      if (error.response) {
        const data = error.response.data as { errors?: any[] };
        if (data?.errors) {
          if (array) {
            errorData = data.errors.map((item: { code: string, message: string, params?: {field: string, reason: string} }) => {
              const errorCodeTranslated = translate({ key: `error.${item.code}`, fallback: item.code });

              if (item.params?.field && item.params?.reason) {
                return {
                  code: errorCodeTranslated === error.code ? (item.message || item.code) : '',
                  message: `${item.params.field}: ${item.params.reason}`
                };
              } else {
                return {
                  code: errorCodeTranslated,
                  message: errorCodeTranslated === item.code ? item.message : ''
                };
              }
            });
          } else {
            const errors = data.errors.map((item: { code: string, message: string, params?: {field: string, reason: string} }) => {
              const errorCodeTranslated = translate({ key: `error.${item.code}`, fallback: item.code });

              return `${item.code}: ${errorCodeTranslated === item.code ? item.message : errorCodeTranslated}`;
            });
            errorData = errors.join('/n');
          }
        }
      }
      break;
    default:
      if (error.response) {
        const data = error.response.data as { errors?: any[] };
        if (data?.errors) {
          if (array) {
            errorData = data.errors.map((item: { code: string, message: string }) => {
              const errorCodeTranslated = translate({ key: `error.${item.code}`, fallback: item.code });

              return { code: item.code, message: errorCodeTranslated === item.code ? item.message : errorCodeTranslated };
            });
          } else {
            const errors = data.errors.map((item: { code: string, message: string }) => {
              const errorCodeTranslated = translate({ key: `error.${item.code}`, fallback: item.code });

              return `${item.code}: ${errorCodeTranslated === item.code ? item.message : errorCodeTranslated}`;
            });
            errorData = errors.join('/n');
          }
        }
      }
  }

  return errorData;
};
