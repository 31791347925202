import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

import apiConfig from '../config/api';
import { clearAuthUserData } from './clearAuthUserData';
import { getAPIToken } from './getAPIToken';
import { configAxios } from './configAxios';
import { logout } from './logout';
import { getCookie, setCookie } from './cookie';

const REFRESH_TOKEN_BEFORE_EXPIRE_TIME = 60000; // 1 min before expire time

export const preflightAPICall = async(callback: () => unknown) => {
  const now = new Date().getTime();
  const authCookie = getCookie('authentication_result');

  const parsedAuthData = authCookie && JSON.parse(authCookie);
  if (!parsedAuthData) {
    clearAuthUserData();
    window.location.href = '/login?logout=true';
  }

  const decodedToken: Record<string, unknown> = jwtDecode(parsedAuthData.access_token);

  const expiresAt = decodedToken?.exp as number * 1000; // Convert to the milliseconds
  const username = decodedToken?.username as string;
  const refreshToken = parsedAuthData.refresh_token;

  if (!expiresAt || now >= (expiresAt - REFRESH_TOKEN_BEFORE_EXPIRE_TIME)) {
    return await axios.post(`${apiConfig.IDENTIFIER}/token/refresh/${apiConfig.TENANT}`,
      {
        'username': username,
        'refresh_token': refreshToken,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then(response => {
      const data = response.data;
      const authResults = {
        ...parsedAuthData,
        access_token: data.authentication_result?.access_token,
        id_token: data.authentication_result?.id_token,
      };

      delete authResults.id_token;
      setCookie('authentication_result', JSON.stringify(authResults), 365, window._env_.COOKIE_DOMAIN);

      configAxios({ authHeader: `${authResults.token_type} ${authResults.access_token}` });
      return callback();
    }).catch(() => {
      logout();
    });
  } else {

    if (!axios.defaults.headers.common['Authorization']) {
      configAxios({ authHeader: getAPIToken() });
    }

    return callback();
  }

};
